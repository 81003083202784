/* for md size */
@media (min-width: 768px) {
  table#dashboardAppointmentTable .dateTime {
    width: 8%;
  }
  table#dashboardAppointmentTable .responses {
    width: 12%;
  }
  table#dashboardAppointmentTable th.actions:after {
    content: '\A\A';
    white-space: pre;
  }
}

/* for lg size and over */
@media (min-width: 992px) {
  table#dashboardAppointmentTable .dateTime {
    width: 9%;
  }
  table#dashboardAppointmentTable .responses {
    width: 10%;
  }
  table#dashboardAppointmentTable .appointmentSortCaret {
    white-space: nowrap;
  }
  table#dashboardAppointmentTable th.actions:after {
    content: none;
  }
}

table#dashboardAppointmentTable th,
table#dashboardAppointmentTable td {
  padding: 1em;
}

table#dashboardAppointmentTable .customer {
  width: 10%;
}

table#dashboardAppointmentTable .serviceType {
  width: 9%;
}

table#dashboardAppointmentTable .checkedIn {
  width: 7%;
}

table#dashboardAppointmentTable .customerId {
  width: 10%;
}

table#dashboardAppointmentTable .examinerNumber {
  width: 10%;
}

table#dashboardAppointmentTable .location {
  width: 9%;
}

table#dashboardAppointmentTable .actions {
  width: 7%;
}

.dashboard-appointment-action-button::after {
  content: none; /* Removes arrow from action buttons in dashboard */
}

.cancelledAppointment {
  background-color: rgb(255, 206, 214) !important;
}
