.splash-screen-wrapper {
  width: 100%;
  background-color: #fff;
  position: fixed;
}

.splash-screen {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 70vh;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  -webkit-animation: fadeInFromNone 0.5s ease-out;
  -moz-animation: fadeInFromNone 0.5s ease-out;
  -o-animation: fadeInFromNone 0.5s ease-out;
  animation: fadeInFromNone 0.5s ease-out;
}

.splash-screen img {
  width: 400px;
}

.loading-text {
  color: grey;
  margin-top: 20px;
}
.loading-text:after {
  content: ".";
  animation: loadingDots 1s steps(5, end) infinite;
}

.loading-text.error:after {
  content: "";
  animation: none;
}

@keyframes fadeInFromNone {
  0% {
    display: block;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0.1;
  }

  40% {
    display: block;
    opacity: 0.4;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes loadingDots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: grey;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 grey, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 grey, 0.5em 0 0 grey;
  }
}
