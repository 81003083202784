/* for md size */
@media (min-width: 768px) { 
    table#examsTable .date {
        width: 11%;
    }

    table#examsTable .action-summary {
        width: 10%;
    }  

    table#examsTable .result {
        width: 7%;
    }

    table#examsTable .customer {
        width: 13%;
        word-wrap: break-word;
    }

    table#examsTable .examType {
        width: 9%;
    }
}

/* for lg size and over */
@media (min-width: 992px) { 
    table#examsTable .date {
        width: 6%;
    }  
    
    table#examsTable .action-summary {
        width: 4%;
    }

    table#examsTable .result {
        width: 5%;
    }

    table#examsTable .examType {
        width: 4%;
    }

    table#examsTable .customer {
        width: 12%;
        word-wrap: break-word;
    }

    table#examsTable .action-email {
        width: 4%;
    }
}




table#examsTable .examiner {
    width: 12%;
    word-wrap: break-word;
}

table#examsTable .action-pdf {
    width: 4%;
}

span.examSummaryText {
    color: #999999;
    white-space: pre;
}

.note-wrap {
    display: block;
    color: #999999;
    white-space: "pre-wrap";
    overflow-wrap: "break-word";
}

span.action-icon:hover {
    color: #0069d9;
}
