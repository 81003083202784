@use "variables";

// Typography
h1, .h1 {
    line-height: $h1-line-height;
}

h2, .h2 {
    line-height: $h2-line-height;
}

h3, .h3 {
    line-height: $h3-line-height;
}

h4, .h4 {
    line-height: $h4-line-height;
}

h5, .h5 {
    line-height: $h5-line-height;
}

h6, .h6 {
    line-height: $h6-line-height;
}

.display-1 {
    line-height: $display1-line-height;      
}

.display-2 {
    line-height: $display2-line-height;
}

.display-3 {
    line-height: $display3-line-height;
}

.display-4 {
    line-height: $display4-line-height;
}

small, .small {
    line-height: $small-line-height;
}

.lead {
    line-height: $lead-line-height;
}

.blockquote {
    line-height: $blockquote-line-height;
}

.blockquote-footer {
    line-height: $blockquote-footer-line-height;
}


// Pagination
.page-link {
    line-height: $pagination-line-height;
}

.pagination-sm .page-link {
    line-height: $pagination-line-height-sm;
}

.pagination-lg .page-link {
    line-height: $pagination-line-height-lg;
}

.react-bootstrap-table-pagination-total {
    margin-left: 0.5em;
}

.react-bs-table-sizePerPage-dropdown .dropdown-menu {
    z-index: 1111;
}


// Modal
h1.modal-title {
    line-height: $h1-line-height;
}

h2.modal-title {
    line-height: $h2-line-height;
}

h3.modal-title {
    line-height: $h3-line-height;
}

h4.modal-title {
    line-height: $h4-line-height;
}

h5.modal-title {
    line-height: $h5-line-height;
}

h6.modal-title {
    line-height: $h6-line-height;
}


// Carousel
.carousel-caption {
  bottom: $carousel-caption-position-bottom;
  padding-top: $carousel-caption-padding-y;
  padding-bottom: $carousel-caption-padding-y;
}

// Custom ======================================================================
.page-item.active .page-link {
    z-index: 0;
}

div.contentContainer {
    padding-bottom: 56px;
}


div.nav-sub-menu {
    margin: 8px 0 0 0 !important;
}

// Bootstrap doesn't support multi-level sub menus
div.nav-sub-sub-menu {
    width: 100%;
    border: 0;
}

div.nav-sub-sub-menu .btn {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    text-align: left;
    border: 0;
    font-weight: 400;
    color: $dropdown-link-color;
    background-color: $dropdown-bg;
    border-radius: 0;
}

div.nav-sub-sub-menu .btn:hover {
    color: $dropdown-link-hover-color;
    text-decoration: none;
    background-color: $dropdown-link-hover-bg;
}

div.nav-sub-sub-menu .btn:active {
    color: $dropdown-link-active-color;
    text-decoration: none;
    background-color: $dropdown-link-active-bg;
}

div.nav-sub-sub-menu .btn:focus {
    text-decoration: none;
}

div.nav-sub-sub-menu .btn:disabled {
    color: $dropdown-link-disabled-color;
    text-decoration: none;
}

div.nav-sub-sub-menu .dropdown-menu.show{
    margin: -9px 0 0 0 !important;
}

button.initialButton {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: #ced4da;
    background-size: cover;
    font-family: Open Sans;
    font-size: 14px;
    color: #212529;
    text-decoration: none solid rgb(33, 37, 41);
    line-height: 14px;
    text-align: center;
    padding: 0;
    border: 0;
}

button.initialButton:after {
    display: none;      // Hides arrow caret on dropdown toggle button
}

.form-label {
    font-weight: 600;
}

footer {
    position: fixed;
    bottom: 0;
    z-index: 1000;  // z-index on modals are > 1000
    width: 100%;
    background-color: white;
}
/* Date input on Dashboard*/
.DateInput #endDate, .DateInput #startDate {
    font-weight: 400;
    font-size: 16px;
    padding: 5px 15px;
}

.DateRangePicker, .DateRangePickerInput__withBorder {
    width: 100%;
    height: 40px;
    border-radius: 0.25rem!important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DateRangePicker .DateInput {
    width: 46%;
}

.DateRangePicker .DateInput #endDate {
    text-align: right;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #007BFF !important;
    border: none !important;
    color: #FFF !important;
}

.CalendarDay__selected_span {
    background: rgba(0, 123, 255, 0.3) !important;
    border: none !important;
    color: #212529 !important;
}

.CalendarDay__today {
    background: none !important;
    border: 2px solid #007BFF !important;
    color: #000 !important;
}

.CalendarDay__selected_span:hover {
    background: rgba(0, 123, 255, 0.6) !important;
    color: #000 !important;
}

/* Table changes */
.table .thead-dark th {
    background-color: #6C757D;
    border-color: #6C757D;
}
.table th, .table td {
    padding: 1em;
}